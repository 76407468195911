import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private authService: AuthService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // if (isPlatformBrowser(this.platformId)) { localStorage.removeItem("user"); }
                localStorage.removeItem("user");
                localStorage.removeItem('unread_notification');
                this.authService.currentUserSubject.next(null)
                this.router.navigate(['/login']);
            }
            const error = err;
            return throwError(error);
        }));
    }
}
