import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl+'guest';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));
  public currentUser = this.currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  login(user) {
    return this.http.post(API_URL+'/login', user).pipe(
      map(user => {
        localStorage.setItem("user", JSON.stringify(user['data']));
        this.currentUserSubject.next(user['data']);
        return user['data'];
      })
    );
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem('unread_notification');
    this.currentUserSubject.next(null);
  }

}
